// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

;globalThis["_sentryRewritesTunnelPath"] = undefined;globalThis["SENTRY_RELEASE"] = {"id":"not-set"};globalThis["_sentryBasePath"] = undefined;globalThis["_sentryRewriteFramesAssetPrefixPath"] = "/assets/proteus";import * as Sentry from "@sentry/nextjs"

import env from "./src/utils/env.mjs"

const SENTRY_DSN = env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  release: env.NEXT_PUBLIC_SENTRY_RELEASE,
  environment: env.NEXT_PUBLIC_ENVIRONMENT,
  enabled: !env.NEXT_PUBLIC_SENTRY_DISABLE,
  beforeBreadcrumb: (breadcrumb) => {
    if (breadcrumb.type === "http" && breadcrumb.data?.url && breadcrumb.data?.url.length > 100) {
      breadcrumb.data.url = breadcrumb.data?.url.slice(0, 100) + "<ZKRACENO>"
    }

    return breadcrumb
  },
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.replayIntegration({
      networkDetailAllowUrls: [window.location.origin],
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // filter out useless errors
  ignoreErrors: [
    // ignore popupsmart from GTM
    /'window.ps.campaigns'/i,
    /cannot read properties of undefined (reading 'campaigns')/i,
    /window.ps is undefined/i,
  ],
})

Sentry.setTag("component", "proteus")
