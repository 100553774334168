import(/* webpackMode: "eager", webpackExports: ["LoginForm"] */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/app/[fqdn]/[locale]/auth/login/LoginForm.tsx");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/icons/DeltaGreenLogoHorizontal.svg");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/icons/DeltaGreenProteusLogoHorizontalIcon.svg");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/icons/EonLogo.svg");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/icons/LamaEnergyLogo.png");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/icons/LinkInvalidIcon.svg");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/apps/proteus/portal/src/icons/MNDLogo.png");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@14.2.18_react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next-intl@3.25.3_next@14.2.18_react@18.3.1/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager" */ "/builds/deltagreen/delta-monorepo/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.0_@opentelemetry+api@1.9.0_@playwright+test@1.46.1_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["Spacer","Heading"] */ "/builds/deltagreen/delta-monorepo/packages/reactLibrary/dist/index.js");
