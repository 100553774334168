"use client"

import { zodResolver } from "@hookform/resolvers/zod"
import { useTranslations } from "next-intl"
import { useForm } from "react-hook-form"
import { z } from "zod"

import { useTenant } from "@/app/[fqdn]/[locale]/TenantProvider"
import { api } from "@/app/trpc"
import { Card, CardContent, CardFooter } from "@/components/ui/Card"
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormSubmitButton,
} from "@/components/ui/Form"
import { Input } from "@/components/ui/Input"
import { useToastFactory } from "@/components/ui/Sonner"
import { LINKS } from "@/constants/links"
import { useRouter } from "@/i18n/routing"

const formSchema = z.object({
  email: z.string().min(1, "Zadejte prosím email.").email("Zadejte prosím platný email."),
})

type FormValues = z.infer<typeof formSchema>

export function LoginForm(props: { nextUrl?: string }) {
  const t = useTranslations("login_page")
  const toast = useToastFactory()
  const router = useRouter()
  const tenant = useTenant()

  const loginMutation = api.users.login.useMutation({
    onError: (error) => {
      if (error.data?.code === "NOT_FOUND") {
        toast.onError({
          title: "Uživatel nebyl nalezen.",
          description: "Zkontrolujte prosím, že zadaný email je správně.",
        })
        return
      }

      toast.onError()
    },
    onSuccess: () => {
      toast.onSuccess({
        title: "Odkaz na přihlášení byl odeslán na váš email.",
        description: "Pro přihlášení klikněte na odkaz v emailu, který jsme vám poslali.",
      })

      router.push(LINKS.authVerifyRequest)
    },
  })

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    mode: "onBlur",
    defaultValues: { email: "" },
  })

  function onSubmit({ email }: FormValues) {
    loginMutation.mutate({ email, tenantId: tenant.tenantId, nextUrl: props.nextUrl ?? LINKS.home })
  }

  return (
    <Card className="w-full max-w-[570px] border-2">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <CardContent className="px-12 pt-9">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>

                  <FormControl>
                    <Input
                      type="email"
                      className="border-2 placeholder:text-nano-gray-800"
                      placeholder="Zde zadejte váš email"
                      {...field}
                      value={field.value}
                    />
                  </FormControl>

                  <FormDescription className="text-nano-gray-700">
                    Zadejte prosím nový nebo existující email.
                  </FormDescription>
                  <FormMessage />
                </FormItem>
              )}
            />
          </CardContent>

          <CardFooter className="px-12 pb-9">
            <FormSubmitButton className="w-full rounded-md max-sm:h-auto" isLoading={loginMutation.isPending}>
              {t("submit_button")}
            </FormSubmitButton>
          </CardFooter>
        </form>
      </Form>
    </Card>
  )
}
